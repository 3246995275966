import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FilterChips from 'Components/filterChips/index.ts';
import Loading from 'Components/smallComponents/loading.tsx';
import { discardManyFields, resetRequest } from 'Core/actions/request.js';
import { isVehicleSelectedSelector } from 'Core/selectors/fitmentSearch/index.js';
import { createShouldShowFacetPanelSelector } from 'Core/selectors/search.js';
import { resultsLoadingSelector } from 'Core/selectors/show.ts';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import { FacetsPanelConfig } from './common/index.ts';
import Facets from './facets.tsx';

import type { FunctionComponent } from 'react';
import type { Params as FilterChipsParams } from 'Components/filterChips/index.ts';
import type { TemplateFunction, TemplateFunctionInvoker, TemplateResult } from 'Components/types.ts';
import type { Params as FacetsParams } from './facets.tsx';

type ExtraParams = Record<string, unknown>;

export type Params = {
  filterChips: TemplateFunctionInvoker<FilterChipsParams>;
  facets: TemplateFunctionInvoker<FacetsParams>;
  clear: () => void;
  isVehicleSelected: boolean;
} & ExtraParams;

export interface Props {
  template: TemplateFunction<Params>;
  name: string;
  fields?: string[];
  ignoreFields?: string[];
  showAllAlways?: true;
  sortEntries?: string[];
  disableCollapse?: true;
  initCollapsed?: boolean;
  initExpandedFacets?: string[];
  extraParams?: ExtraParams;
  initHidden?: boolean;
  optionsCountToShowFilterInput?: number;
}

const FacetPanel: FunctionComponent<Props> = ({
  template,
  name: facetPanelName,
  fields,
  ignoreFields,
  showAllAlways,
  disableCollapse,
  initCollapsed,
  initExpandedFacets,
  extraParams,
  initHidden,
  optionsCountToShowFilterInput = 30,
}) => {
  const dispatch = useDispatch();

  const isVehicleSelected = useSelector(isVehicleSelectedSelector);

  const showPanel = useShowPanel(fields, ignoreFields);

  if (initHidden && !showPanel) {
    return null;
  }

  const appendedClasses = !showPanel ? 'cm_empty' : '';

  const startOver = () => dispatch(resetRequest());
  const clearPanel = () => fields && dispatch(discardManyFields(fields));
  const onClick = simpleHandler({ startOver, clearPanel });

  const filterChips = (templ: TemplateFunction<FilterChipsParams>) => {
    const props = { template: templ, fields, ignoreFields, key: 'chips' };
    return (<FilterChips {...props} />) as TemplateResult;
  };

  const facets = (templ: TemplateFunction<FacetsParams>) => {
    const props = { template: templ, fields, ignoreFields, key: 'facets' };
    return (<Facets {...props} />) as TemplateResult;
  };

  const loading = !initHidden && <Loading selector={resultsLoadingSelector} key="loading" />;

  const component = template.call({
    filterChips,
    facets,
    ...extraParams,
    isVehicleSelected,
    clear: startOver,
  });
  const facetPanelContext = {
    facetPanelName,
    showAllAlways,
    disableCollapse,
    initCollapsed,
    initExpandedFacets,
    optionsCountToShowFilterInput,
  };
  return (
    <FacetsPanelConfig.Provider value={facetPanelContext}>
      {cloneSafe(component, null, { onClick, appendedClasses, appendedChildren: loading })}
    </FacetsPanelConfig.Provider>
  );
};

export default FacetPanel;

function useShowPanel(fields, ignoreFields) {
  return useSelector(
    useMemo(() => createShouldShowFacetPanelSelector(fields, ignoreFields), [fields, ignoreFields]),
  );
}
