import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeMaxCountByField, resetMaxCountByField } from 'Core/actions/request.js';
import { useShowMore } from 'Core/hooks/index.js';
import { createMaxCountSelector } from 'Core/selectors/search.js';
import { FacetValue } from 'Models/index.ts';
import facetsConfig from 'Models/uiConfig/facetsConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { distribute, splitOnIndex } from 'Utils/array.js';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import { FacetsPanelConfig, SimpleValue, FilterInput } from '../common/index.ts';

const { showAllAutoCollapse } = facetsConfig;

export default function SimpleFacet({
  facet,
  facet: {
    alwaysShowCount,
    values: { length: valuesCount },
    facetedValues,
    field,
    isSingleValue,
    isTree,
  },
  config: { customValuesProcessor = (v) => v },
  templateFunc,
  facetRef,
  commonParams,
  commonParams: { isCollapsed },
  commonRoles,
}) {
  const dispatch = useDispatch();
  const { showAllAlways, optionsCountToShowFilterInput } = useContext(FacetsPanelConfig);
  const [filterValue, setFilterValue] = useState('');
  const maxCount = useSelector(createMaxCountSelector(field));

  const initMaxCount = requestConfig.maxCountByField[field] || null;
  const inputNotEmpty = filterValue.length;
  const showFilterInput = facetedValues.length >= optionsCountToShowFilterInput;

  const [showMoreCollapsed, internalToggleShowMore] = useShowMore(facetRef, showAllAlways);

  if (!facetedValues.length) {
    return null;
  }

  function onSendRequest() {
    if (showAllAutoCollapse && !showMoreCollapsed) {
      internalToggleShowMore();
    }
  }

  const filterInput = (template) => {
    if (!showFilterInput) {
      return null;
    }

    const props = {
      template,
      filterValue,
      facetName: facet.name,
      facetField: facet.field,
      onChange: (e) => setFilterValue(e.target.value),
      key: facet.name,
    };
    return <FilterInput {...props} />;
  };

  const facetValuesFiltered = facetedValues.filter(
    (v) => v.isSelected || v.value.toLowerCase().includes(filterValue.toLowerCase()),
  );

  const orderedValues =
    showAllAlways || facetValuesFiltered.length <= alwaysShowCount || !showMoreCollapsed || isTree
      ? facetValuesFiltered
      : distribute(facetValuesFiltered, (v) => v.isSelected).flat();

  const [ShowAlwaysValues, Values] = splitOnIndex(
    customValuesProcessor(orderedValues, { facet, showMoreCollapsed, FacetValue }).map((value) => (templ) => {
      const props = {
        value,
        isSingleValue,
        template: templ,
        onSendRequest,
        key: value.term,
      };
      return <SimpleValue {...props} />;
    }),
    showAllAlways ? orderedValues.length + 1 : alwaysShowCount,
  );

  if (!ShowAlwaysValues.length && !Values.length && !filterInput) {
    return null;
  }

  const needShowMore = !showAllAlways && !!Values.length;
  const incrementalShowMore = needShowMore && initMaxCount && facetValuesFiltered.length >= initMaxCount;
  const moreValuesToShow = incrementalShowMore ? !!maxCount && valuesCount === maxCount : showMoreCollapsed;

  const toggleShowMore =
    incrementalShowMore && !showMoreCollapsed
      ? () => {
          if (moreValuesToShow) {
            dispatch(removeMaxCountByField(field));
          } else {
            dispatch(resetMaxCountByField(field));
            internalToggleShowMore();
          }
        }
      : internalToggleShowMore;

  const onClick = simpleHandler({ ...commonRoles, toggleShowMore });

  const component = templateFunc.call({
    ...commonParams,
    filterInput,
    showFilterInput,
    inputNotEmpty,
    needShowMore,
    moreValuesToShow,
    ShowAlwaysValues,
    Values: !isCollapsed && !showMoreCollapsed && Values,
    valuesCount,
    clearInput: () => setFilterValue(''),
  });
  return cloneSafe(component, facetRef, { onClick });
}
