import _differenceBy from 'lodash-es/differenceBy.js';

const getByKey = (key) => (o) => (typeof key === 'function' ? key(o) : o[key]);

export function equalByMany(obj1, obj2, ...compareKeys) {
  if (!obj1 || !obj2) {
    return false;
  }
  return compareKeys.every((compareKey) => {
    if (Array.isArray(compareKey)) {
      const [key, ...keys] = compareKey;
      const get = getByKey(key);
      if (Array.isArray(obj1)) {
        return (
          obj1.length === obj2.length &&
          obj1.length &&
          obj1.every((o, i) => equalByMany(get(o), get(obj2[i]), ...keys))
        );
      }
      return equalByMany(get(obj1), get(obj2), ...keys);
    }

    const get = getByKey(compareKey);
    if (Array.isArray(obj1)) {
      return obj1.length === obj2.length && (!obj1.length || obj1.every((o, i) => get(o) === get(obj2[i])));
    }
    return get(obj1) === get(obj2);
  });
}

export function comparerBy(comparer) {
  return (arr1, arr2) => equalBy(arr1, arr2, comparer);
}

export function equalBy(arr1, arr2, comparer) {
  return arr1 === arr2 || (arr1.length === arr2.length && !_differenceBy(arr1, arr2, comparer).length);
}

export function distribute(array, predicate, thisArg) {
  return [array.filter(predicate, thisArg), array.filter((...args) => !predicate(...args), thisArg)];
}

export function splitOnIndex(array, index) {
  return [array.slice(0, index), array.slice(index)];
}
