import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import SliderStrip from 'Components/slider/sliderStrip.jsx';
import { replaceManyValues } from 'Core/actions/request.js';
import { useIdleEffect } from 'Core/hooks/index.js';
import { FacetValue } from 'Models/index.ts';
import { decimalToImperial, getPercent as getNumPercent } from 'Utils/ranged.ts';
import './slider.scss';

export default function Slider({
  field,
  bounds,
  selected: sel_pr,
  looseOnEdges,
  step,
  mode: mode_pr = 'Range',
}) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(sel_pr.map(norm));
  const activeRef = useRef(false);
  const selRef = useRef(selected);
  const rootRef = useRef();
  const titlesRef = useRef([]);

  let mode = mode_pr;

  useEffect(() => {
    selRef.current = selected;
  }, [selected]);

  useIdleEffect(() => {
    titleStyles().forEach(({ left = null, right = null }, i) => {
      const elem = titlesRef.current[i];
      if (elem) {
        elem.style.right = appendPxToTruthy(right);
        elem.style.left = appendPxToTruthy(left);
      }
    });
  });

  function norm(value, i) {
    return value === '*' || bounds[0] === bounds[1] ? ['0', '100'][i] : getNumPercent(+value, bounds);
  }
  function rescale(value, i) {
    const [min, max] = bounds;
    return step.fit(((max - min) / 100) * value + +min, ['floor', 'ceil'][i]);
  }
  if (!activeRef.current) {
    const newSelected = sel_pr.map(norm);
    if (newSelected.some(isNaN)) {
      throw new Error('New selected for Slider is NaN');
    }
    if (newSelected[0] !== selected[0] || newSelected[1] !== selected[1]) {
      setSelected(newSelected);
    }
    if (bounds[0] === bounds[1]) {
      mode = 'Lock';
    }
  }

  function titleStyles() {
    const titles = titlesRef.current.filter(Boolean);
    if (!titles.length) {
      return [];
    }
    const widths = titles.map((t) => (t ? t.offsetWidth : 0));
    const sliderWidth = rootRef.current.offsetWidth;

    const borders = widths.map((w) => (100 / sliderWidth) * w);
    borders[1] = 100 - borders[1];

    return [
      selected[0] < borders[0]
        ? { left: 0 }
        : selected[0] > borders[1]
          ? { right: widths[1] }
          : { right: sliderWidth - (selected[0] / 100) * sliderWidth },

      selected[1] > borders[1]
        ? { right: 0 }
        : selected[1] < borders[0]
          ? { left: widths[0] }
          : { left: (selected[1] / 100) * sliderWidth },
    ];
  }

  const titles = [0, 1].map((i) => {
    if ((mode === 'UpperBound' && i === 0) || (mode === 'LowerBound' && i === 1) || mode === 'Lock') {
      return null;
    }
    const titleProps = {
      className: `cm_slider-title${activeRef.current ? ' visible' : ''}`,
      ref: (elem) => (titlesRef.current[i] = elem),
    };
    const value = rescale(selected[i], i);
    return (
      <div {...titleProps} key={`${field}_SlTtl${['Lt', 'Rt'][i]}`}>
        {step.imperial ? decimalToImperial(value) : value}
      </div>
    );
  });

  const onChangeFinished = () => {
    activeRef.current = false;

    const sel = selRef.current.map((arg, i) => (looseOnEdges && arg === [0, 100][i] ? '*' : rescale(arg, i)));
    if (sel[0] !== sel_pr[0] || sel[1] !== sel_pr[1]) {
      const term = FacetValue.createRangedTerm(...sel);
      const isSelected = sel.some((s) => s !== '*');
      dispatch(
        replaceManyValues([{ field, term, isSelected }], {
          mayDiscardValue: true,
        }),
      );
    }
  };

  const stripProps = {
    field,
    selected,
    mode,
    onChangeStarted: () => (activeRef.current = true),
    onChange: setSelected,
    onChangeFinished,
  };
  const strip = <SliderStrip {...stripProps} />;

  const props = {
    className: 'cm_slider-container',
    ref: rootRef,
  };
  return (
    <div {...props}>
      {titles}
      {strip}
    </div>
  );
}

function appendPxToTruthy(value) {
  return value && `${value}px`;
}
