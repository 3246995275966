import { useMemo, cloneElement } from 'react';
import { useDispatch } from 'react-redux';

import { toggle } from 'Core/actions/request.js';
import { FacetValue, Step } from 'Models/index.ts';
import { cloneSafe } from 'Utils/components.ts';
import { getBoundsFromFacet, getValuesFromTerm, fit, decimalToImperial } from 'Utils/ranged.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import { Slider, RangedInputs } from '../common/index.ts';

import type { FC, MouseEventHandler } from 'react';
import type { TemplateFunction, TemplateFunctionInvoker, TemplateResult } from 'Components/types.ts';
import type { MinMax } from 'Utils/ranged.ts';
import type { FacetCommonProps, CommonParams } from '../baseFacet.js';
import type { Params as RangedInputsParams } from '../common/rangedInputs.js';

type Params = Omit<CommonParams, 'selection'> & {
  slider: JSX.Element;
  lockedSlider: JSX.Element;
  min: number;
  max: number;
  isNullRange: boolean;
  selection: MinMax;
  Inputs: TemplateFunctionInvoker<RangedInputsParams>;
};

type Props = Omit<FacetCommonProps, 'templateFunc'> & {
  templateFunc: TemplateFunction<Params>;
};

const RangedSliderFacet: FC<Props> = ({
  facet,
  field,
  templateFunc,
  facetRef,
  commonParams,
  commonRoles,
  config: { showAlways, step: step_pr = '1', slider: { bounds: bounds_sl, mode } = {} },
}) => {
  const dispatch = useDispatch();
  const step = useMemo(() => new Step(step_pr), [step_pr]);

  const selected: MinMax = facet.selection[0] ? getValuesFromTerm(facet.selection[0].term) : ['*', '*'];
  const bounds: MinMax = bounds_sl ? bounds_sl.map((m) => step.fit(m)) : getBoundsFromFacet(facet, step);
  if (bounds[0] === bounds[1] && !showAlways) {
    return null;
  }

  const sliderProps = {
    field,
    bounds,
    looseOnEdges: !bounds_sl,
    selected: fit(selected, bounds),
    step,
    mode,
  };
  const slider = <Slider {...sliderProps} />;
  const lockedSlider = cloneElement(slider, { mode: 'Lock' });

  const onClick: MouseEventHandler<HTMLElement> = simpleHandler({ ...commonRoles });

  const [min, max] = bounds.map((m) => +m);
  const selection = selected.map((sel, i) => {
    const value = sel.replace(/^\*$/, String([min, max][i]));
    return step.imperial ? decimalToImperial(value) : value;
  }) as MinMax;

  function inputsSubmitHandler([selMin, selMax]: MinMax) {
    const term = FacetValue.createRangedTerm(selMin, selMax);
    const isSelected = !(selMin === '*' && selMax === '*');
    dispatch(toggle({ term, field, isUnique: true, isSelected }, { mayDiscardValue: true }));
  }

  const Inputs = (templ: TemplateFunction<RangedInputsParams>) =>
    (
      <RangedInputs
        template={templ}
        addFacetCallback={inputsSubmitHandler}
        key="inputs"
        bounds={bounds}
        step={step}
        mode={mode}
        selected={selected}
      />
    ) as TemplateResult;

  const component = templateFunc.call({
    ...commonParams,
    slider,
    lockedSlider,
    min,
    max,
    isNullRange: min === max,
    selection,
    Inputs,
  });
  return cloneSafe(component, facetRef, { onClick });
};

export default RangedSliderFacet;
