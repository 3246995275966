import { useRef } from 'react';

import { getPercent } from 'Utils/ranged.ts';
import './style.scss';

const replaceByIndex = (array, index, value) => array.map((v, i) => (i === index ? value : v));

export default function SliderStrip({
  field,
  selected,
  mode,
  color,
  onChangeStarted,
  onChange,
  onChangeFinished,
}) {
  const activeMarkIndex = useRef(-1);

  const containerProps = {
    className: 'cm_strip-container',
  };
  const selectedProps = {
    className: 'cm_strip selected',
    style: color ? { backgroundColor: color } : {},
  };
  if (mode === 'Lock') {
    return (
      <div {...containerProps}>
        <div {...selectedProps} />
      </div>
    );
  }

  function fit(value) {
    const active = activeMarkIndex.current;
    const i = active === 0 ? 1 : active === 1 ? 0 : -1;
    const [min, max] = replaceByIndex([0, 100], i, selected[i]);
    return value < min ? min : value > max ? max : value;
  }
  function handleMove(x, bRect) {
    const coord = fit(getPercent(x, [bRect.left, bRect.right]));

    const dist = (i) => Math.abs(coord - selected[i]);
    const d0 = dist(0);
    const d1 = dist(1);
    if (activeMarkIndex.current < 0 && (d0 || d1)) {
      const catchMinMark = mode === 'LowerBound' || (d0 === d1 && coord < selected[0]);
      const catchMaxMark = mode === 'UpperBound' || (d0 === d1 && coord > selected[0]);

      activeMarkIndex.current = catchMinMark ? 0 : catchMaxMark ? 1 : d0 < d1 ? 0 : 1;
    }
    const index = activeMarkIndex.current;

    onChange(dist(index) ? replaceByIndex(selected, index, coord) : selected);
  }

  const createHandler = (createMoveHandler, isMouse) => (e) => {
    e.preventDefault();
    if (!(e.currentTarget instanceof HTMLElement)) {
      return;
    }
    onChangeStarted();
    const bRect = e.currentTarget.getBoundingClientRect();
    const moveHandler = createMoveHandler(bRect);
    const endEvent = isMouse ? 'mouseup' : 'touchend';
    const moveEvent = isMouse ? 'mousemove' : 'touchmove';

    function endHandler() {
      onChangeFinished();
      activeMarkIndex.current = -1;
      document.removeEventListener(moveEvent, moveHandler);
      document.removeEventListener(endEvent, endHandler);
    }

    moveHandler(e);

    document.addEventListener(moveEvent, moveHandler);
    document.addEventListener(endEvent, endHandler);
  };
  const onMouseMove =
    (bRect) =>
    ({ clientX }) =>
      handleMove(clientX, bRect);
  const onTouchMove =
    (bRect) =>
    ({ changedTouches }) =>
      changedTouches[0] && handleMove(changedTouches[0].clientX, bRect);
  containerProps.onMouseDown = createHandler(onMouseMove, true);
  containerProps.onTouchStart = createHandler(onTouchMove, false);

  const marks = selected.map((s, i) => {
    if ((mode === 'UpperBound' && i === 0) || (mode === 'LowerBound' && i === 1)) {
      return null;
    }
    const markContainerProps = {
      className: 'cm_mark-container',
      style: { left: `${s}%` },
    };
    const markProps = { className: 'cm_mark' };
    if (color) {
      markProps.style = { borderColor: color };
    }
    const key = `${field}_SlMrk${['Lt', 'Rt'][i]}`;
    return (
      <div {...markContainerProps} key={key}>
        <div {...markProps} />
      </div>
    );
  });
  selectedProps.style.left = `${selected[0]}%`;
  selectedProps.style.right = `${100 - selected[1]}%`;
  return (
    <div {...containerProps}>
      <div className="cm_strip" />
      <div {...selectedProps} />
      {marks}
    </div>
  );
}

SliderStrip.defaultProps = {
  onChangeStarted: () => {},
};
