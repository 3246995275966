import { createContext } from 'react';

interface FacetPanelConfig {
  facetPanelName: string;
  optionsCountToShowFilterInput: number;
  showAllAlways?: true;
  disableCollapse?: true;
  initCollapsed?: boolean;
  initExpandedFacets?: string[];
}

export default createContext<FacetPanelConfig>({ facetPanelName: '', optionsCountToShowFilterInput: 30 });

export const FacetDialogName = 'FacetDialog';
