import _ceil from 'lodash-es/ceil.js';
import _floor from 'lodash-es/floor.js';

import type { Step, Facet } from 'Models/index.ts';

export type MinMax = [string, string];

export function getValuesFromTerm(term: string): MinMax {
  // expected terms are `${value}` and `${min} TO ${max}`
  const split = term.split(' ');
  if (split.length === 1) {
    return [split[0], split[0]];
  }
  if (split.length === 3) {
    return [split[0], split[2]];
  }
  console.warn(`Expected '{min} TO {max}' term, but received ${term}`);
  return ['*', '*'];
}

export function getBoundsFromFacet(facet: Facet, step: Step): MinMax {
  const res = step.fitMinMax(getRawBoundsFromFacet(facet, step));
  return +res[0] >= +res[1] ? [res[0], res[0]] : res;
}

function getRawBoundsFromFacet(facet: Facet, step: Step): MinMax {
  if (facet.ranges?.length) {
    return ['0', '0'];
  }

  const allValues = facet.values
    .flatMap((v) => getValuesFromTerm(v.term))
    .filter((v) => v !== '*')
    .map(Number);

  return [
    _ceil(Math.min(...allValues), step.accuracy).toString(),
    _floor(Math.max(...allValues), step.accuracy).toString(),
  ];
}

export function fit(values: MinMax, [min, max]: MinMax): MinMax {
  return values.map((v, i) => {
    if (v === '*') {
      return [min, max][i];
    }
    if (+v > +max) {
      return max;
    }
    return +v < +min ? min : v;
  }) as MinMax;
}

export function rangeFiniteness(range: MinMax): { finite: boolean; toPosInf: boolean; toNegInf: boolean } {
  const openMin = range[0] === '*';
  const openMax = range[1] === '*';
  return {
    finite: !openMin && !openMax,
    toPosInf: !openMin && openMax,
    toNegInf: openMin && !openMax,
  };
}

export function decimalToImperial(value: number | string): string {
  const v = +value;
  const ft = Math.floor(v);
  const inch = Math.round((v - ft) * 12);
  return ft ? `${ft}'${inch}"` : `${inch}"`;
}

export function getPercent(value: number, [min, max]: MinMax): number {
  return (100 / (+max - +min)) * (+value - +min);
}
