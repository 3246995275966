import { KeyboardEventHandler, useEffect, useMemo, useRef, useState } from 'react';

import { Step } from 'Models/index.ts';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';

import type { FC, MouseEventHandler, ChangeEventHandler } from 'react';
import type { TemplateFunction } from 'Components/types.ts';
import type { MathOps } from 'Models/index.ts';
import type { MinMax } from 'Utils/ranged.ts';

export type Params = {
  inputMin?: (templ: TemplateFunction<void>) => JSX.Element;
  inputMax?: (templ: TemplateFunction<void>) => JSX.Element;
};

type Props = {
  template: TemplateFunction<Params>;
  bounds?: MinMax;
  step?: Step;
  selected?: MinMax;
  mode?: string;
  addFacetCallback: (selection: MinMax) => void;
};

const inputErrorClass = 'cm_input-error';

const RangedInputs: FC<Props> = ({
  template,
  bounds: [min, max] = ['0', '*'],
  step: step_pr,
  selected,
  mode,
  addFacetCallback,
}) => {
  const inputsRef = useRef<[HTMLInputElement | null, HTMLInputElement | null]>([null, null]);
  const timeoutRef = useRef<number>();
  const step = useMemo(() => new Step(step_pr), [step_pr]);

  const [inputs, setInputs] = useState<MinMax>(['', '']);
  const [errors, setErrors] = useState<[boolean, boolean]>([false, false]);

  useEffect(() => {
    if (selected) {
      inputsRef.current.forEach((input) => input?.classList.remove(inputErrorClass));
      const cleanSelection = selected.map((sel) => (sel === '*' ? '' : sel)) as MinMax;
      setInputs(cleanSelection);
    }
  }, [selected]);

  if (min === max && max !== '*') {
    return null;
  }

  const addFacet = () => {
    if (errors.some(Boolean)) {
      return;
    }

    const rawSelection = inputs.map((input, i) => {
      if (input === '' || (i === 0 && min === '*') || (i === 1 && max === '*')) {
        return input || '*';
      }
      return +input < +min ? min : +input > +max ? max : input;
    }) as MinMax;

    const selection = inputsRef.current.map((input, i) =>
      input && input.step && rawSelection[i] !== '*'
        ? step.fit(+rawSelection[i], ['floor', 'ceil'][i] as MathOps)
        : rawSelection[i],
    ) as MinMax;

    addFacetCallback(selection);
  };

  const onClick: MouseEventHandler<HTMLElement> = simpleHandler({ addFacet });
  const onChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const [newInputs, newErrors] = handleInputErrors(target, inputsRef.current, inputs, errors);
    setInputs(newInputs);
    setErrors(newErrors);
  };
  const onKeyDown: KeyboardEventHandler<HTMLElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      addFacet();
    }
  };

  const [inputMin, inputMax] = ['min', 'max'].map((m, i) => (templ: TemplateFunction<void>) => {
    const inputProps = templ()?.props;
    const props = {
      placeholder: [min, max][i],
      ...inputProps,
      type: 'number',
      value: inputs[i],
      step: step.numeric,
      min,
      max,
      onChange,
      ['data-cm-role']: `input-${m}`,
      key: `input-${m}`,
      ref: (el) => (inputsRef.current[i] = el),
    };

    return <input {...props} />;
  });

  if (mode === 'LowerBound') {
    return cloneSafe(template.call({ inputMin }), null, { onClick, onKeyDown });
  }

  if (mode === 'UpperBound') {
    return cloneSafe(template.call({ inputMax }), null, { onClick, onKeyDown });
  }

  return cloneSafe(template.call({ inputMin, inputMax }), null, { onClick, onKeyDown });
};

function handleInputErrors(
  target: HTMLInputElement,
  inputElements: [HTMLInputElement | null, HTMLInputElement | null],
  inputs: MinMax,
  errors: [boolean, boolean],
): [MinMax, [boolean, boolean]] {
  const currentChangingInputI = ['input-min', 'input-max'].indexOf(
    target.getAttribute('data-cm-role') as string,
  );
  const newErrors = [...errors] as [boolean, boolean];
  const setError = (inputI: number, isErrorShowing: boolean) => {
    newErrors[inputI] = isErrorShowing;
    inputElements[inputI]?.classList.toggle(inputErrorClass, isErrorShowing);
  };

  const { value: currentChangingInputValue } = target;

  const newInputs = inputs.map((inputValue, i) => {
    const currentValue = i === currentChangingInputI ? currentChangingInputValue : inputValue;

    if (currentValue === '' || !isNaN(+currentValue)) {
      setError(i, false);
      return currentValue;
    }
    if (i === currentChangingInputI) {
      setError(currentChangingInputI, true);
      return currentValue;
    }
    return '';
  }) as MinMax;

  if (!newInputs.includes('') && +newInputs[0] > +newInputs[1]) {
    setError(currentChangingInputI, true);
  }
  return [newInputs, newErrors];
}

export default RangedInputs;
