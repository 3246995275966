import {
  addToGarageRequested,
  setGarageRequested,
  removalFromGarageRequested,
  vehicleSelected,
  vehicleDiscardingRequested,
  sendVerifyFitmentRequest,
} from 'Core/actions/fitmentSearch/index.js';
import {
  fitmentSearchBaseFieldsSelector,
  fitmentSearchFieldsSelector,
  garageDataSelector,
  vehicleSelectionSelector,
  selectedVehicleSelector,
  isVehicleSelectedSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { verifyFitmentProductDataSelector } from 'Core/selectors/product.ts';
import { Vehicle, VehicleCollection } from 'Models/index.ts';
import productConfig from 'Models/uiConfig/productConfig.js';
import { search } from 'Modules/serverApi/index.js';

import type { ReduxStateType, ReduxStoreType } from 'Core/store.ts';
import type { FacetRequest } from 'Models/index.ts';

type FieldMap = Record<string, string>;

type FitmentSearchAPI = {
  getVehicle: () => FieldMap;
  isVehicleSelected: () => boolean;
  getVehicleSearchParams: () => string;
  setVehicle: (vehicle: FieldMap) => void | Error;
  discardVehicle: () => void;
  addVehicle: (vehicle: FieldMap) => void | Error;
  removeVehicle: (vehicle: FieldMap) => void;
  getVehicleList: () => FieldMap[];
  setVehicleList: (vehicles: FieldMap[]) => void | Error;
  addVehicleList: (vehicles: FieldMap[]) => void | Error;
  verifyFitmentAsync: (productId?: string | FieldMap, vehicle?: FieldMap) => Promise<string | undefined>;
  updateVerifyFitmentWidget: (productId?: number) => void;
};

export default function getFitmentSearchAPI(store: ReduxStoreType): FitmentSearchAPI {
  const { dispatch } = store;

  return {
    getVehicle: () => selectedVehicleSelector(store.getState()).filteredFieldMap,
    isVehicleSelected: () => isVehicleSelectedSelector(store.getState()),
    getVehicleSearchParams: () => {
      const selection = vehicleSelectionSelector(store.getState()) as FacetRequest[];

      return selection.map((s) => `${s.field}/${s.term}`).join('/');
    },
    setVehicle: (vehicle) => {
      const state = store.getState();

      if (!isVehicleHasAllRequiredFields(state, Object.keys(vehicle))) {
        throwIncompleteVehicleError(state);
      }

      dispatch(vehicleSelected(new Vehicle(vehicle, fitmentSearchFieldsSelector(state))));
    },
    discardVehicle: () => {
      dispatch(vehicleDiscardingRequested({ mayDiscardValue: false }));
    },
    addVehicle: (vehicle) => {
      const state = store.getState();

      if (!isVehicleHasAllRequiredFields(state, Object.keys(vehicle))) {
        throwIncompleteVehicleError(state);
      }

      dispatch(addToGarageRequested(new Vehicle(vehicle, fitmentSearchFieldsSelector(state))));
    },
    removeVehicle: (vehicle) => {
      const state = store.getState();

      const vehicleToRemove = new Vehicle(vehicle, fitmentSearchFieldsSelector(state));
      const selectedVehicle = selectedVehicleSelector(state);

      dispatch(removalFromGarageRequested(vehicleToRemove, vehicleToRemove.equals(selectedVehicle)));
    },
    getVehicleList: () => garageDataSelector(store.getState()).map((vehicle) => vehicle.filteredFieldMap),
    setVehicleList: (vehicles) => {
      const state = store.getState();

      if (vehicles.some((vehicle) => !isVehicleHasAllRequiredFields(state, Object.keys(vehicle)))) {
        throwIncompleteVehicleError(state);
      }

      dispatch(
        setGarageRequested({
          vehicles: new VehicleCollection(
            vehicles.map((vehicle) => new Vehicle(vehicle, fitmentSearchFieldsSelector(state))),
          ),
          selectVehicle: true,
        }),
      );
    },
    addVehicleList: (vehicles) => {
      const state = store.getState();

      if (vehicles.some((vehicle) => !isVehicleHasAllRequiredFields(state, Object.keys(vehicle)))) {
        throwIncompleteVehicleError(state);
      }

      const currentVehicles = garageDataSelector(state);
      const newVehicles = new VehicleCollection(
        vehicles.map((vehicle) => new Vehicle(vehicle, fitmentSearchFieldsSelector(state))),
      );

      const combinedVehicles = currentVehicles.concat(newVehicles);

      if (combinedVehicles.size > currentVehicles.size) {
        dispatch(setGarageRequested({ vehicles: combinedVehicles, selectVehicle: false }));
      }
    },
    verifyFitmentAsync: (productId, vehicle) => {
      const state = store.getState();

      const isObject = (obj) => typeof obj === 'object' && obj !== null && !Array.isArray(obj);

      // only vehicle provided
      if (isObject(productId) && !vehicle) {
        return verifyFitmentAsyncInternal(state, undefined, productId as FieldMap);
      }

      if (productId && typeof productId !== 'string') {
        throw Error('Provided product id must be a string value');
      }

      if (vehicle && !isObject(vehicle)) {
        throw Error('Provided vehicle must be an object');
      }

      return verifyFitmentAsyncInternal(state, productId, vehicle);
    },
    updateVerifyFitmentWidget: (productId: number) => {
      if (productId) {
        const state = store.getState();
        const selectedVehicle = selectedVehicleSelector(state) as Vehicle;

        dispatch(sendVerifyFitmentRequest(selectedVehicle, productId));
      } else {
        throw new Error('Provided product id not found');
      }
    },
  };
}

function isVehicleHasAllRequiredFields(state: ReduxStateType, fields: string[]): boolean {
  const baseFitmentSearchFields = fitmentSearchBaseFieldsSelector(state);
  return baseFitmentSearchFields.every((field: string) => fields.includes(field));
}

function throwIncompleteVehicleError(state: ReduxStateType): never {
  const baseFitmentSearchFields = fitmentSearchBaseFieldsSelector(state);
  throw new Error(`Vehicle must have all required fields: ${baseFitmentSearchFields.join(', ')}.`);
}

async function verifyFitmentAsyncInternal(
  state: ReduxStateType,
  productId: string = productConfig.localItemId,
  vehicle?: FieldMap,
): Promise<string | undefined> {
  const selectedVehicle = selectedVehicleSelector(state) as Vehicle;

  const vehicleToVerify = vehicle
    ? new Vehicle(vehicle, fitmentSearchFieldsSelector(state))
    : selectedVehicle;

  if (vehicleToVerify === Vehicle.null || !productId) {
    let message = '';

    if (!productId) {
      message +=
        'Function must be used on the product page or product id must be provided as the first function parameter. ';
    }

    if (vehicleToVerify === Vehicle.null) {
      message += `Vehicle must be selected on the page or provided as the second function parameter with all required fields: ${fitmentSearchBaseFieldsSelector(
        state,
      ).join(', ')}.`;
    }

    throw new Error(message);
  }

  const productData = verifyFitmentProductDataSelector(state);
  const fitsTheVehicle = productData?.fitsTheVehicle;

  if (productId === productData?.id && fitsTheVehicle && vehicleToVerify.equals(selectedVehicle)) {
    return fitsTheVehicle;
  }

  const request = {
    selection: vehicleToVerify.selection,
    pageSize: 1,
    mode: 'VerifyFitment',
    filterQuery: `${productConfig.fields.id}:${productId}`,
  };
  return search(request).then((res) => {
    const [item] = res.Items;

    if (!item) {
      throw new Error(
        `No product found with id: '${productId}'. Please check if product id is correct or start reindexing.`,
      );
    }

    const fitsTheVehicle = item?.fitsTheVehicle;

    if (fitsTheVehicle) {
      return item.fitsTheVehicle as string;
    }

    throw new Error('Something went wrong. If this error persists, please notify Convermax.');
  });
}
