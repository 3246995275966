import { FC } from 'react';
import { useDispatch } from 'react-redux';

import ColorTile from 'Components/smallComponents/colorTile.tsx';
import { FacetValue, WeightedColor } from 'Models/index.ts';
import facetsConfig from 'Models/uiConfig/facetsConfig.js';
import { cloneSafe } from 'Utils/components.ts';
import { isTermColor, isTermRanged } from 'Modules/termType.ts';
import { getValuesFromTerm, rangeFiniteness, decimalToImperial } from 'Utils/ranged.ts';

import type { AnyAction } from 'redux';
import type { TemplateFunction } from 'Components/types.ts';
import type { SearchOptions } from 'Core/reducers/search/lastSearchOptions.ts';
import type { FacetValueFull } from 'Models/index.ts';

export type JoinChipEntry = { joinKey: string; value: Record<string, string> };

export type Params =
  | ({ template: 'rangeChip'; selection: string[] } & ReturnType<typeof rangeFiniteness> & FacetValueFull)
  | ({
      template: 'colorChip';
      percent: number | string;
      color: string;
      colorTile: JSX.Element;
    } & FacetValueFull)
  | ({ template: 'reviewChip' } & FacetValueFull)
  | ({ template: 'chip' } & FacetValueFull)
  | ({ template: string } & JoinChipEntry);

export interface Props {
  template: TemplateFunction<Params>;
  value: FacetValueFull | JoinChipEntry;
  discardValue: (value: FacetValueFull | JoinChipEntry, options: SearchOptions) => AnyAction;
}

const Chip: FC<Props> = ({ template, value, discardValue }) => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(discardValue(value, { mayDiscardValue: true }));
  const component = template.call(getParams(value));

  return cloneSafe(component, null, {
    onClick,
    key: 'joinKey' in value ? value.joinKey : FacetValue.valueKey(value),
  });
};

export default Chip;

function getParams(entry: FacetValueFull | JoinChipEntry): Params {
  if ('joinKey' in entry) {
    return { template: entry.joinKey, ...entry };
  }

  const { field, term } = entry;

  if (field !== 'query') {
    if (isTermRanged(term)) {
      const selected = getValuesFromTerm(term);
      const selection = selected.map((v) =>
        facetsConfig.useImperialForField(field) ? decimalToImperial(v) : `${+v}`,
      );
      return {
        ...entry,
        selection,
        ...rangeFiniteness(selected),
        template: 'rangeChip',
      };
    }

    if (isTermColor(term)) {
      const { color, weight: percent } = new WeightedColor(term);
      const colorTile = <ColorTile color={color} />;
      return {
        ...entry,
        percent,
        color,
        colorTile,
        template: 'colorChip',
      };
    }

    if (field === 'review') {
      return { ...entry, template: 'reviewChip' };
    }
  }

  return { ...entry, template: 'chip' };
}
