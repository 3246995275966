import { useLayoutEffect, useState, useRef, RefObject, MutableRefObject, useCallback } from 'react';

import uiConfig from 'Models/uiConfig/uiConfig.js';
import { animateLinear } from 'Utils/animatePromise.js';
import useIdleEffect from './idleEffect.js';

type UseCollapse = [collapse: boolean, toggle: () => void];

type CollapsibleOptionsType = {
  disableCollapse?: boolean;
  initCollapsed?: boolean;
  howToDisableCollapse?: string;
  openHeight?: number;
  prevExpandConditionRef?: MutableRefObject<boolean | undefined>;
  expandCondition?: boolean;
};

export function useCollapseFacet(
  rootRef: RefObject<HTMLElement | undefined>,
  disableCollapse: boolean | undefined,
  initCollapsed: boolean | undefined,
  hasSelection: boolean | undefined,
  hadSelectionRef: MutableRefObject<boolean>,
): UseCollapse {
  return useCollapsible(
    rootRef,
    '.facetbody',
    (!disableCollapse && uiConfig.facetsCollapsedDefault && !hasSelection) || initCollapsed,
    {
      disableCollapse,
      howToDisableCollapse: 'set disableCollapse to true in the FacetPanel widget config',
      expandCondition: hasSelection,
      prevExpandConditionRef: hadSelectionRef,
    },
  );
}

export function useShowMore(root: RefObject<HTMLElement>, showAllAlways: boolean): UseCollapse {
  return useCollapsible(root, '.cmRepeater_Values', !showAllAlways, {
    disableCollapse: showAllAlways,
    howToDisableCollapse: 'set showAllAlways to true in the FacetPanel widget config',
  });
}

const expandValue = 'expand';
const collapseValue = 'collapse';
const instantEnding = '-instant';
const instantExpandValue = `${expandValue}${instantEnding}`;

const isCollapsed = (st: string) => st.startsWith(collapseValue);
const isInstant = (st: string) => st.endsWith(instantEnding);

const animatedAttr = 'data-cm-animated';
const collapsedClass = 'collapsed';

export function useCollapsible(
  rootRef: RefObject<HTMLElement | undefined>,
  selector: string | null,
  initCollapsed: boolean,
  {
    disableCollapse,
    howToDisableCollapse,
    openHeight,
    expandCondition: expandNeeded,
    prevExpandConditionRef: prevExpandNeededRef = { current: undefined },
  }: CollapsibleOptionsType = {},
): UseCollapse {
  const [state, setState] = useState(`${initCollapsed ? collapseValue : expandValue}${instantEnding}`);
  const instant = isInstant(state);
  const collapse = isCollapsed(state);

  const animElemRef = useRef<HTMLElement>();
  const cancelAnimation = useRef<() => void>();

  useLayoutEffect(function initializeAnimatedElement() {
    if (disableCollapse) {
      return;
    }
    const root = rootRef.current;
    const elem = root?.querySelector(selector || '.cm_collapsible-elem');
    if (elem instanceof HTMLElement) {
      if (elem !== animElemRef.current) {
        animElemRef.current = elem;
        elem.style.position = 'relative';
      }
      if (instant) {
        if (collapse) {
          elem.style.height = '0';
          elem.style.overflow = 'hidden';
          elem.classList.add(collapsedClass);
        } else {
          elem.style.height = openHeight ? `${openHeight}` : '';
        }
      }
    } else if (root && selector) {
      console.warn(
        `animElem with "${selector}" selector for useCollapsible is not an HTMLElement:
        ${elem}${
          howToDisableCollapse ? `\nIf collapsible functionality not needed, ${howToDisableCollapse}.` : ''
        }`,
      );
    }
  });

  useLayoutEffect(
    function expandInstantly() {
      if (collapse && !prevExpandNeededRef.current && expandNeeded) {
        setState(instantExpandValue);
      }
      prevExpandNeededRef.current = expandNeeded;
    },
    [collapse, expandNeeded, prevExpandNeededRef],
  );

  useIdleEffect(
    function handleAnimation() {
      if (disableCollapse) {
        return;
      }
      const animElem = animElemRef.current;
      if (!animElem || hasAnimatedChild(animElem)) {
        return;
      }

      cancelAnimation.current?.();
      if (!collapse) {
        animElem.classList.remove(collapsedClass);
      }

      const { height, maxHeight } = getHeights(animElem);
      const newHeight = (!collapse && (openHeight || maxHeight)) || 0;
      if (height === newHeight) {
        return;
      }

      animElem.style.overflow = 'hidden';
      animElem.setAttribute(animatedAttr, '');

      const { promise, cancel } = animateLinear(
        height,
        newHeight,
        (h) => (animElem.style.height = `${h}px`),
        uiConfig.collapsibleAnimationTime,
      );
      cancelAnimation.current = cancel;

      promise.then(() => {
        if (!collapse) {
          animElem.style.overflow = openHeight ? 'auto' : '';
          animElem.style.height = openHeight ? `${openHeight}` : '';
        } else {
          animElem.classList.add(collapsedClass);
        }
        animElem.removeAttribute(animatedAttr);
      });
    },
    [collapse, disableCollapse, openHeight],
    { timeout: 500 },
  );

  const toggle = useCallback(
    () => setState((prevState) => (isCollapsed(prevState) ? expandValue : collapseValue)),
    [],
  );
  return [collapse, toggle];
}

function hasAnimatedChild(elem: HTMLElement): boolean {
  return !!(elem && elem.querySelector(`[${animatedAttr}]`));
}

function getHeights(elem: HTMLElement): { height: number; maxHeight: number } {
  const children = [...elem.children] as HTMLElement[];
  const { offsetTop = 0, offsetHeight = 0 } = children.pop() || {};
  return { height: elem.offsetHeight, maxHeight: offsetTop + offsetHeight };
}
