import { useDispatch, useSelector } from 'react-redux';

import { toggleColor } from 'Core/actions/request.js';
import { dialogOpened } from 'Core/actions/dialog.ts';
import { searchResponseStateSelector } from 'Core/selectors/search.js';
import { FacetValue, WeightedColor } from 'Models/index.ts';
import { cloneSafe } from 'Utils/components.ts';
import { simpleHandler } from 'Utils/roleHandler.js';
import { ColorSlider } from '../common/index.ts';

import ColorTile from 'Components/smallComponents/colorTile.tsx';

const defaultPaletteColors = [
  ['Black', '101010'],
  ['Taupe', 'b09c82'],
  ['Gray', '808080'],
  ['Silver', 'c0c0c0'],
  ['Beige', 'f5f5dc'],
  ['White', 'ffffff'],
  ['Burgundy', '750f17'],
  ['Red', 'c00718'],
  ['Terracotta', 'b24d38'],
  ['Coral', 'f77564'],
  ['Orange', 'fa760a'],
  ['Peach', 'fbbd93'],
  ['Brown', '4f2907'],
  ['Dark Green', '204b21'],
  ['Olive', '808000'],
  ['Green', '248a0f'],
  ['Yellow', 'ffd246'],
  ['Gold', 'deaa0d'],
  ['Navy', '132b53'],
  ['Blue', '1f5e9e'],
  ['Peacock', '008080'],
  ['Aqua', '55c2c3'],
  ['Light Blue', '90c1e4'],
  ['Purple', '67357e'],
  ['Pink', 'eb6fa4'],
  ['Tan', 'dac8a0'],
];

export default function AdvancedColorFacet({
  field,
  selection,
  config: { showAlways, paletteColors = defaultPaletteColors },
  templateFunc,
  facetRef,
  commonParams,
  commonRoles,
}) {
  const dispatch = useDispatch();

  const noResults = useSelector((state) => searchResponseStateSelector(state) === 'nothing');

  if (noResults && !showAlways) {
    return null;
  }

  const sliders = selection.map((sel) => {
    const { color } = new WeightedColor(sel.term);
    const props = { color, field };
    return <ColorSlider {...props} key={color} />;
  });

  const paletteItems = paletteColors.map(([name, color]) => {
    const { term } = new WeightedColor(color);
    const value = { field, term };
    const isSelected = FacetValue.isInCollection(value, selection);
    const onClick = () => dispatch(toggleColor(value, { mayDiscardValue: true }));
    const props = { color, isSelected, name, onClick };
    return <ColorTile {...props} key={color} />;
  });

  const onClick = simpleHandler({ ...commonRoles });

  const openSimplePaletteDialog = () => dispatch(dialogOpened('SimpleColorPaletteDialog'));
  const openColorExtractorDialog = () => {
    uploadImage().then(
      (extractorData) => dispatch(dialogOpened('ColorExtractorDialog', { extractorData })),
      (err) => err && console.warn(err),
    );
  };

  const component = templateFunc.call({
    ...commonParams,
    sliders,
    paletteItems,
    openSimplePaletteDialog,
    openColorExtractorDialog,
  });
  return cloneSafe(component, facetRef, { onClick });
}

function uploadImage() {
  return new Promise((res, rej) => {
    const input = document.getElementById(CONVERMAX_IMAGE_BUFFER_ID);
    input.click();
    input.onchange = ({ target: { files: [file] = [] } }) => {
      input.value = null;
      if (file) {
        res(file);
      } else {
        rej('No file provided');
      }
    };
  }).then(
    (file) =>
      new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = ({ target: { result } }) => {
          if (typeof result === 'string') {
            res({ filename: file.name, src: result });
          } else {
            rej('ArrayBuffer support needed');
          }
        };
      }),
  );
}
